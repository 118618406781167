<template>
    <div>
    <div v-if="is_mounted">
        <h5>{{title}}</h5>

        <q-card flat>
            <q-tabs
                v-model="tab"
                inline-label
            >
                <q-tab name="contratti_pdf" label="PDF Contratti"  v-show="showContratti" />
            <!--    <q-tab name="documenti_allegati" label="Documenti allegati" /> //-->
                <q-tab name="documenti_allegati" label="Documenti allegati" />
                <q-tab name="documenti_contratti" label="Set Informativi" />
                <q-tab name="simpli_pdf" label="Simpli" />
            </q-tabs>

            <q-tab-panels v-model="tab" animated>

                <q-tab-panel name="documenti_allegati">
                    <div class="text-h6">Documenti Allegati</div>
                    Clicca su un documento per scaricarlo
                    <br><br>

                    <q-list separator bordered>
                        <q-item
                            v-for="(documento,index) in elenco_documenti.documenti_proposta.documenti" v-bind:key="index"
                            clickable
                            v-ripple
                            @click.native="onScaricaDocumentoDaUrl(documento.descrizione,documento.path)"
                        >
                            <q-item-section avatar>
                                <q-avatar color="orange-3" text-color="black" icon="mdi-text-box-check-outline" />
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>{{ documento.descrizione }}</q-item-label>
                            </q-item-section>
                            <q-item-section avatar>
                                <q-avatar color="orange-3" text-color="black" icon="mdi-download" />
                            </q-item-section>
                        </q-item>
                    </q-list>

                </q-tab-panel>

                <q-tab-panel name="documenti_contratti">
                    <div class="text-h6">Documenti specifici dei contratti</div>
                    Clicca su un documento per scaricarlo
                    <br><br>
                    <q-list separator bordered v-for="(prodotto,index) in getProdottiSelezionati"
                        v-bind:key="index"
                        class="q-mb-md">

                        <q-item-label header>
                            <q-avatar color="orange-3" text-color="black" icon="mdi-check-outline"  size="36px" />
                            {{prodotto.label}}
                        </q-item-label>

                        <q-item v-for="(tariffa,index_tariffa) in getTariffaSelezionata(prodotto)"
                            v-bind:key="index_tariffa">

                            <q-list separator style="width: 100%;">
                                <q-item>
                                    <q-item-section>
                                        <q-item-label>
                                            Compagnia
                                        </q-item-label>
                                        <q-item-label caption>
                                            {{tariffa.compagnia}} - {{tariffa.label}}
                                        </q-item-label>
                                    </q-item-section>
                                    <q-item-section>
                                        <q-item-label>
                                            Tariffa
                                        </q-item-label>
                                        <q-item-label caption>
                                            {{tariffa.tariffa}}
                                        </q-item-label>
                                    </q-item-section>

                                </q-item>

                                <q-item v-for="(documento,index_documento) in getElencoDocumenti(tariffa)"
                                    v-bind:key="index_documento"
                                    clickable v-ripple
                                    @click.native="onScaricaDocumentoDaUrl(documento.id,documento.path)">

                                    <q-item-section>
                                        <q-item-label>
                                            {{documento.descrizione}}
                                        </q-item-label>
                                    </q-item-section>
                                    <q-item-section avatar>
                                        <q-avatar color="orange-3" text-color="black" icon="mdi-download" />
                                    </q-item-section>
                                </q-item>

                            <!--    <q-item
                                    v-for="(documento,index) in elenco_documenti.documenti_catalogo.documenti" v-bind:key="index"
                                    clickable
                                    v-ripple
                                    @click.native="onScaricaDocumentoDaUrl(documento.descrizione,documento.path)"
                                >
                                    <q-item-section>
                                        <q-item-label>{{documento.descrizione}}</q-item-label>
                                    </q-item-section>
                                    <q-item-section avatar>
                                        <q-avatar color="orange-3" text-color="black" icon="mdi-download" />
                                    </q-item-section>

                                </q-item>

                                <q-item v-for="(documento,index_documento) in getElencoDocumenti(tariffa)"
                                    v-bind:key="index_documento"
                                    clickable v-ripple
                                    @click.native="onScaricaDocumentoDaUrl(documento.id,documento.path)">
                                    <q-item-section>
                                        <q-item-label>
                                            {{documento.descrizione}}
                                        </q-item-label>
                                    </q-item-section>
                                    <q-item-section avatar>
                                        <q-avatar color="orange-3" text-color="black" icon="mdi-download" />
                                    </q-item-section>

                                </q-item> //-->
                            </q-list>
                        </q-item>
                    </q-list>
                </q-tab-panel>

                <q-tab-panel name="contratti_pdf" v-show="showContratti">

                    <q-list separator bordered
                        class="q-mb-md">

                        <q-item v-for="(contratto_pdf,index_pdf) in elenco_contratti_pdf" v-bind:key="index_pdf"
                            clickable
                            v-ripple
                            @click.native="onScaricaDocumentoDaUrl(contratto_pdf.tariffa,contratto_pdf.url)">
                            <q-item-section>
                                <q-item-label>
                                    Compagnia
                                </q-item-label>
                                <q-item-label caption>
                                    {{contratto_pdf.compagnia}}
                                </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>
                                    Prodotto
                                </q-item-label>
                                <q-item-label caption>
                                    {{contratto_pdf.prodotto_id}}
                                </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>
                                    Nome del file
                                </q-item-label>
                                <q-item-label caption>
                                    {{contratto_pdf.filename}}
                                </q-item-label>
                            </q-item-section>
                            <q-item-section avatar>
                                <q-avatar color="orange-3" text-color="black" icon="mdi-download" />
                            </q-item-section>

                        </q-item>

                    </q-list>
                </q-tab-panel>

                <q-tab-panel name="simpli_pdf" v-show="showSimpli">
                    <q-list separator bordered
                        class="q-mb-md">

                        <q-item v-for="(contratto_pdf,index_pdf) in elenco_contratti_pdf" v-bind:key="index_pdf"
                            clickable
                            v-ripple
                            @click.native="onScaricaDocumentoDaUrl(contratto_pdf.tariffa,contratto_pdf.url)">
                            <q-item-section>
                                <q-item-label>
                                    Compagnia
                                </q-item-label>
                                <q-item-label caption>
                                    {{contratto_pdf.compagnia}}
                                </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>
                                    Prodotto
                                </q-item-label>
                                <q-item-label caption>
                                    {{contratto_pdf.prodotto_id}}
                                </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>
                                    Nome del file
                                </q-item-label>
                                <q-item-label caption>
                                    {{contratto_pdf.filename}}
                                </q-item-label>
                            </q-item-section>
                            <q-item-section avatar>
                                <q-avatar color="orange-3" text-color="black" icon="mdi-download" />
                            </q-item-section>

                        </q-item>

                    </q-list>
                </q-tab-panel>

            </q-tab-panels>
        </q-card>
    </div>
        <br>
        <div class="text-center justify-center q-pa-md q-gutter-sm fixed-center"
            v-show="!is_loading"
            style="z-index: 1000;">

            <q-spinner-hourglass
                size="4em"
            />

        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import { mapFields} from 'vuex-map-fields';
    import commonLib from "@/libs/commonLib"
    import * as Sentry from "@sentry/vue";
    import axios from 'axios';

    export default {
        name: "QQElencoDocumentiPratica",
        data() {
            return {
                elenco_documenti: [],
                elenco_contratti_pdf: [],
                elenco_simpli: [],
                tab: 'documenti_allegati',
                is_mounted: false,
                is_loading: false
            }
        },
        props: {
            title: {
                type: String,
                required: true
            },
            id_pratica: {
                type: String,
                required: true
            },
            formPratica: {
                type: Object,
                required: true
            },
            tabIndex: {
                type: String,
                required: false,
                default: "0"
            },
            showContratti: {
                type: Boolean,
                required: false,
                default: false
            },
            showSimpli: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        methods: {
            ...mapActions({
                fetchDownloadUrl: "gestioneDocumenti/fetchDownloadUrl",
                fetchElencoCompletoDocumentiPratica: "gestioneDocumenti/fetchElencoCompletoDocumentiPratica",
                fetchElencoDocumentiTariffa: "gestioneDocumenti/fetchElencoDocumentiTariffa",
                fetchDownloadUrlContratti: "gestioneDocumenti/fetchDownloadUrlContratti",
                //fetchElencoSimpli: "gestioneDocumenti/fetchElencoSimpli"
            }),
            async onScaricaDocumentoDaUrl(fileName,url) {

                //console.log("url:",url);
                //console.log("filename:",fileName);

                // Elimina estensione .pdf dal nome del file
                var filename_minuscolo = fileName.toLowerCase();
                fileName = filename_minuscolo.replace(".pdf","");

                if ((commonLib.isEmpty(url)) || (url.toLowerCase() === "documento non trovato")) {

                    this.$q.dialog({
                        title: 'Attenzione',
                        message:  "documento non trovato",
                    });

                    return;
                }

                await axios({
                     url:url,
                     method:'GET',
                     responseType: 'blob',
                    headers:{ Authorization:""}
                 })
                 .then((response) => {
                    const url = window.URL
                        .createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.target = "_blank";
                    link.setAttribute('download', fileName+".pdf");
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                 });

                // window.location = url;
            },
            async onScaricaDocumento(documento) {
                var NumeroPreventivo = this.id_pratica; // this.formPratica.preventivo.id;
                var anno = documento.annoCreazione;
                var mese = documento.meseCreazione+"";

                mese = mese.padStart(2,"0");

                var Filename = "documenti/preventivi/"+anno+"/"+mese+"/"+NumeroPreventivo+"/"+documento.label;

                // Richiese url per il download
                var url = await this.fetchDownloadUrl(Filename);
                //console.log("documento.label:",documento.label);

                if (commonLib.isEmpty(url)) {
                    Sentry.captureException(new Error("documento non trovato"),
                    {
                        extra : {
                            numero_preve: NumeroPreventivo,
                            nome_documento: Filename,
                            url_documento: url,
                        }
                    });

                    this.$q.dialog({
                        title: 'Attenzione',
                        message: "Documento non trovato",
                    });
                    return;
                }

                await axios({
                     url:url,
                     method:'GET',
                     responseType: 'blob',
                    headers:{ Authorization:""}
                 })
                 .then((response) => {
                    const url = window.URL
                        .createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.target = "_blank";
                    link.setAttribute('download', documento.label);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                 });

                //window.location = url;
            },
            async ScaricaTuttiIDocumenti() {
                let prodotti = this.formPratica.preventivo.prodotti;
                for (let indiceProdotto=0; indiceProdotto < prodotti.length; indiceProdotto++) {
                    let prodotto = prodotti[indiceProdotto];
                    if(! prodotto.is_checked) {
                        continue;
                    }

                    let create_at = commonLib.castDateToIso(this.formPratica.preventivo.create_at);
                    //console.log("proposte:",prodotto.proposte);

                    for (let indiceProposta=0;indiceProposta<prodotto.proposte.length;indiceProposta++) {
                        let proposta = prodotto.proposte[indiceProposta];
                        if(! proposta.is_checked) {
                            continue;
                        }
                        var dati = {
                            id_tariffa: proposta.tariffa,
                            data_create_at: create_at
                        }

                        let doc = await this.fetchElencoDocumentiTariffa(dati);
                        let nome_tariffa = proposta.tariffa;

                        //console.log("nome tariffa:",nome_tariffa);

                        this.elenco_docs[nome_tariffa] = new Array();
                        this.elenco_docs[nome_tariffa]= doc;
                    }
                }
            },
            getElencoDocumenti(tariffa) {
                let nome_tariffa = tariffa.tariffa;

                // Cerca la tariffa nel catalogo prodotti
                var documento = this.elenco_documenti.documenti_catalogo.find(d => {
                    return d.id === nome_tariffa;
                });

                if (commonLib.isEmpty(documento)) return [];

                return documento.documenti;
            },
            getTariffaSelezionata(prodotto_selezionato) {
                //console.log("prodotto selezionato:",prodotto_selezionato);
                var tariffa_selezionata =
                    prodotto_selezionato.proposte.filter(tariffa => {
                        return tariffa.is_checked;
                    });

                //console.log("tariffa selezionata:",tariffa_selezionata);

                return tariffa_selezionata;

            }
        },
        computed: {
            ...mapFields("gestioneDocumenti", {
                //is_loading: "is_loading",
                isDocumentiPraticaScaricati: "isDocumentiPraticaScaricati",
                elenco_docs: "elenco_docs"
            }),
            getProdottiSelezionati() {
                var prodotti_selezionati =
                    this.formPratica.preventivo.prodotti.filter(prodotto => {
                        return prodotto.is_checked;
                    });

                //console.log("prodotto selezionato:",prodotti_selezionati);

                return prodotti_selezionati;
            },
            getTab() {
                switch(this.tabIndex) {
                    case "0": return "contratti_pdf";
                    case "1": return "documenti_allegati";
                    case "2": return "documenti_generati";
                    case "3": return "documenti_contratti";
                    case "4": return "simpli_pdf";
                }

                return "documenti_allegati";
            }
        },
        async mounted() {
            this.is_loading = false;
            this.is_mounted = false;
            var decorrenza = this.formPratica.preventivo.create_at;

            //console.log("decorrenza:",decorrenza);

            if (!this.isDocumentiPraticaScaricati) {
                await this.ScaricaTuttiIDocumenti();
            }

            if (this.showContratti) {
                this.elenco_contratti_pdf = await this.fetchDownloadUrlContratti(this.formPratica.preventivo.id);
            }

            console.log("QQElencoDocumenti -> id_pratica:",this.id_pratica);
            console.log("QQElencoDocumenti -> decorrenza:",decorrenza);

            this.elenco_documenti = await this.fetchElencoCompletoDocumentiPratica({id_pratica: this.id_pratica, decorrenza: decorrenza});
            this.tab = this.getTab;

            console.log("QQElencoDocumenti -> elenco_documenti:",this.elenco_documenti);

            if (this.showSimpli) {
                this.elenco_simpli = this.elenco_documenti.simpli;
            }

            this.is_loading = true;
            this.is_mounted = true;
        },
    /*    async activated() {
            this.is_loading = false;
            var decorrenza = this.formPratica.preventivo.create_at;

            if (!this.isDocumentiPraticaScaricati) {
                await this.ScaricaTuttiIDocumenti();
            }

            this.elenco_documenti = await this.fetchElencoCompletoDocumentiPratica({id_pratica: this.id_pratica, decorrenza: decorrenza});
            this.tab = this.getTab;

            this.is_loading = true;
        }*/
    }

</script>
