<template>
    <div>
        <div align="center">
            <span class="text-h5">Verifica dati dei Questionari</span>
            <hr>
        </div>

        <div class="row justify-center">
            <div class="col-12 col-md-5 q-mr-sm">
                <h5>Analisi necessità</h5>

                <q-list bordered separator>
                    <q-item v-for="(domanda,index) in getDomandeAnalisiNecessita()"
                            v-bind:key="index">
                        <q-item-section>
                            <q-item-label>{{domanda.label}}</q-item-label>
                            <q-item-label caption>{{domanda.value}}</q-item-label>
                        </q-item-section>

                    </q-item>
                </q-list>

                <br>
                <h5 class="q-mt-sm q-mb-md">Analisi del Rischio</h5>

                <q-list bordered separator
                    class="q-mb-md"
                    v-for="(sezione_domanda,index) in getDomandeAnalisiRischio()"
                    v-bind:key="index">
                    <q-item-label header>{{sezione_domanda.label}}</q-item-label>

                    <q-item v-for="(domanda,indexDomanda) in sezione_domanda.domande.filter(d => {return d.value !== ''})"
                            v-bind:key="indexDomanda">
                        <q-item-section>
                            <q-item-label v-html="domanda.label"></q-item-label>
                            <q-item-label caption>{{analizzaRisposta(domanda)}}</q-item-label>
                        </q-item-section>
                    </q-item>
                </q-list>
                <br>
            </div>

            <div class="col-12 col-md-5 q-mr-sm">
                <QQElencoDocumentiPratica
                    title=""
                    tabIndex = "1"
                    :id_pratica="IDPratica"
                    :formPratica="formPratica"
                />
            </div>

            <div class="col-12 col-md-12">
                <hr>
            </div>

            <div class="row col-12 col-md-12 justify-center">

                <div class="col-md-3" align="center">
                    <QQButton label="INDIETRO" color="blue-grey" icon="undo" size="md"
                        @click.native="onIndietroClicked()"
                    />
                </div>
                <div class="col-md-3" align="center">
                    <QQButton label="Segnala problemi" color="blue-grey" icon="mdi-close-octagon" size="md"
                        @click.native="onSegnalaProblemi()"
                    />
                </div>
                <div class="col-md-3" align="center">
                    <QQButton v-if="PuoLavorareLaPratica()"
                        label="Annulla Pratica"
                        v-show="PuoCancellareLaPratica()"
                        color="blue-grey"
                        icon="mdi-delete"
                        size="md"
                        @click.native="onAnnullaPratica()"/>
                </div>
                <div class="col-md-3" align="center">
                    <QQButton label="AVANTI"
                        color="blue-grey"
                        icon="mdi-arrow-right-bold"
                        size="md"
                        icon_align="right"
                        @click.native="onAvantiClicked()"
                    />
                </div>
            </div>

        </div>

    </div>
</template>

<script>
    import { mapState,mapActions } from 'vuex'
    import QQButton from "@/components/QQButton.vue";
    import QQElencoDocumentiPratica from "@/components/QQElencoDocumentiPratica.vue";
    import policy from "@/libs/policy";

    export default {
        name: "ControlloDatiCliente",
        components: {
            QQButton,
            QQElencoDocumentiPratica
        },
        data() {
            return {

            }
        },
        computed: {
            ...mapState({
                area: state => state.area,
                titolo_area: state => state.titolo_area,
                formPratica: state => state.gestionePratiche.formPratica
            }),
            IDPratica() {
                return this.formPratica.preventivo.id;
            }
        },
        methods: {
            ...mapActions({
                fetchDownloadUrl: "gestioneDocumenti/fetchDownloadUrl"
            }),
            PuoLavorareLaPratica() {
                return policy.AbilitaLavorazionePreventivi();
            },
            PuoCancellareLaPratica() {
                return policy.PuoCancellarePraticheProprie() ||
                       policy.PuoCancellarePraticheGruppo() ||
                       policy.PuoCancellareTutteLePratiche();
            },
            onAnnullaPratica() {
                this.$q.dialog({
                        cancel: {
                        focus: true,
                        flat: false,
                        label: "No, neanche un po`",
                        color: "primary"
                    },
                    ok: {
                        color: "secondary",
                        flat: false,
                        label: "Si, annulla"
                    },
                    title: 'Attenzione',
                    message: 'Sei sicuro di voler annullare questa pratica?',
                }).onOk(async () => {
                    //await this.setAnnullaPratica(this.RecordSelezionato.numero_interno);
                    //await this.fetchElencoPreventivi("");
                    this.$router.push({name : "Pratiche.AnnullaPratica"});
                });
            },
            onSegnalaProblemi() {
                this.$emit("on-segnala-problemi");
            },
            onIndietroClicked() {
                this.$emit("on-back-clicked");
            },
            onAvantiClicked() {
                this.$emit("on-avanti-clicked");
            },
            getDomandeAnalisiNecessita() {
                return this.formPratica.preventivo.analisi_necessita.filter(domanda => {
                    return domanda.value !== "";
                });
            },
            getDomandeAnalisiRischio() {
                return this.formPratica.preventivo.analisi_rischio;
            },
            analizzaRisposta(parametro) {
                let result = "";

                if (parametro.type === "checkbox") {
                    return parametro.is_checked ? "Si" : "No";
                }

                var value = parametro.value;
                var options = parametro.options;

                // Cerca la label tra le options in base alla risposta inserita
                let element = options.filter(elemento => {
                    return elemento.value === value;
                })
                if (element.length === 1) {
                    value = element[0].label;
                }

                if ((value !== null) && (Array.isArray(value))) {
                    value.forEach(valore => {
                        result += '"'+valore+'", ';
                    });
                    result = result.slice(0, -2);
                    return result.replace("_"," ");
                } else {
                    if (value !== null) {
                        return value.replace("_"," ");
                    } else
                        return "";
                }
            }
        },
        async mounted() {
        },
        async activated() {
        }
    }
</script>
