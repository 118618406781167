<template>
    <div>
        <div align="center">
            <span class="text-h5">Verifica dati delle polizze</span>
            <hr>
        </div>

        <div class="row">

            <div class="col-12 col-md-6 q-mr-sm">

                <h5>Proposte selezionate</h5>

                <q-list separator bordered v-for="(prodotto,index) in getProdottiSelezionati"
                        v-bind:key="index"
                        class="q-mb-md">

                    <q-item-label header>
                        <q-avatar color="orange-3" text-color="black" icon="mdi-check-outline"  size="36px" />
                        {{prodotto.label}}
                    </q-item-label>

                    <q-item>

                    <!--    <q-item-section>
                            <q-item-label>
                                Tariffa
                            </q-item-label>
                            <q-item-label caption>
                                {{getTariffaSelezionata(prodotto)}}
                            </q-item-label>
                        </q-item-section> //-->

                        <q-item-section>
                            <q-item-label>
                                Compagnia e Fornitore
                            </q-item-label>
                            <q-item-label caption>
                                - Compagnia: {{getCompagniaSelezionata(prodotto)}}<br>
                                - Fornitore: {{getFornitoreSelezionato(prodotto)}}
                            </q-item-label>
                        </q-item-section>

                        <q-item-section>
                            <q-item-label>
                                Date e Frazionamento
                            </q-item-label>
                            <q-item-label caption>
                                - Decorrenza: {{getDataDecorrenzaPratica}}<br>
                                - Scadenza: {{getScadenzaContrattuale(prodotto)}}<br>
                                - Frazionamento: {{getFrazionamentoPratica(prodotto)}}
                            </q-item-label>
                        </q-item-section>

                        <q-item-section>
                            <q-item-label>
                                Premi rata
                            </q-item-label>
                            <q-item-label caption>
                                - Premio rata: {{getPremioRata(prodotto)}}<br>
                                - Provvigioni rata: {{getProvvigioniRata(prodotto)}}
                            </q-item-label>
                        </q-item-section>

                    </q-item>

                    <q-item v-for="(garanzia, indexGaranzia) in getPropostaSelezionata(prodotto).parametri"
                            v-bind:key="indexGaranzia"
                            v-bind="garanzia">
                        <q-item-section>
                            <q-item-label>
                                {{garanzia.label}}
                            </q-item-label>
                            <q-item-label caption>
                                {{ analizzaRisposta(garanzia) }}
                            </q-item-label>
                        </q-item-section>
                    </q-item>

                </q-list>

            </div>

            <div class="col-12 col-md-5 q-mr-sm">
                <QQElencoDocumentiPratica
                    title=""
                    tabIndex = "3"
                    :id_pratica="IDPratica"
                    :formPratica="formPratica"
                />
            </div>

            <div class="col-12 col-md-12">
                <hr>
            </div>

            <div class="row col-12 col-md-12 justify-center">

                <div class="col-md-3" align="center">
                    <QQButton label="INDIETRO" color="blue-grey" icon="undo" size="md"
                        @click.native="onIndietroClicked()"
                    />
                </div>
                <div class="col-md-3" align="center">
                    <QQButton label="Segnala problemi" color="blue-grey" icon="mdi-close-octagon" size="md"
                        @click.native="onSegnalaProblemi()"
                    />
                </div>
                <div class="col-md-3" align="center">
                    <QQButton v-if="PuoLavorareLaPratica()"
                        label="Annulla Pratica"
                        v-show="PuoCancellareLaPratica()"
                        color="blue-grey"
                        icon="mdi-delete"
                        size="md"
                        @click.native="onAnnullaPratica()"/>
                </div>
                <div class="col-md-3" align="center">
                    <QQButton label="SALVA"
                        color="blue-grey"
                        icon="mdi-content-save-outline"
                        size="md"
                        icon_align="right"
                        :disabled="!pulsante_abilitato"
                        @click.native="onAvantiClicked()"
                    />
                </div>
            </div>

        </div>

    </div>
</template>

<script>
    import { mapState,mapActions } from 'vuex'
    import QQButton from "@/components/QQButton.vue";
    import QQElencoDocumentiPratica from "@/components/QQElencoDocumentiPratica.vue";
    import policy from "@/libs/policy";
    import commonLib from "@/libs/commonLib";

    export default {
        name: "ControlloPolizze",
        components: {
            QQButton,
            QQElencoDocumentiPratica
        },
        data() {
            return {
                elenco_documenti_scaricati: [],
                pulsante_abilitato: true
            }
        },
        computed: {
            ...mapState({
                area: state => state.area,
                titolo_area: state => state.titolo_area,
                formPratica: state => state.gestionePratiche.formPratica
            }),
            IDPratica() {
                return this.formPratica.preventivo.id;
            },
            getProdottiSelezionati() {
                var prodotti_selezionati =
                    this.formPratica.preventivo.prodotti.filter(prodotto => {
                        return prodotto.is_checked;
                    });

                return prodotti_selezionati;
            },
            getDataDecorrenzaPratica() {
                return this.formPratica.preventivo.decorrenza;
            }
        },
        methods: {
            ...mapActions({
                // fetchElencoDocumentiDaPreventivo: "gestioneDocumenti/fetchElencoDocumentiDaPreventivo",
                fetchDownloadUrl: "gestioneDocumenti/fetchDownloadUrl",
                setPraticaInLavorazione: "gestionePratiche/setPraticaInLavorazione"
            }),
            attivaPulsanti() {
                this.pulsante_abilitato = true;
            },
            disattivaPulsanti() {
                this.pulsante_abilitato = false;
                 setTimeout(()=>{
                    this.attivaPulsanti()
                }, 10000);
            },
            getFrazionamentoPratica(prodotto) {
                 //return this.formPratica.preventivo.frazionamento.toUpperCase();
                var proposta = this.getPropostaSelezionata(prodotto)
                return proposta.frazionamento.toUpperCase();
            },
            getTariffaSelezionata(prodotto) {
                var label = this.getPropostaSelezionata(prodotto).label;
                return label;
            },
            getCompagniaSelezionata(prodotto) {
                var compagnia = this.getPropostaSelezionata(prodotto).compagnia;
                return compagnia;
            },
            getFornitoreSelezionato(prodotto) {
                var fornitore = this.getPropostaSelezionata(prodotto).fornitore;
                return fornitore;
            },
            getDataScadenzaPratica() {
                var data = this.getDataDecorrenzaPratica.split("/");
                var decorrenza = new Date(data[2],data[1],data[0]);
                var scadenza = new Date(decorrenza.getFullYear()+1,decorrenza.getMonth(),decorrenza.getDate());
                var giorno = scadenza.getDate()+'';
                var mese   = scadenza.getMonth()+'';

                giorno = giorno.padStart(2,'0');
                mese = mese.padStart(2,'0');

                return giorno+'/'+mese+'/'+scadenza.getFullYear();
            },
            getPropostaSelezionata(prodotto) {
                if (commonLib.isEmpty(prodotto.proposte)) return {};

                var proposta = prodotto.proposte.filter(proposta => {
                    return proposta.is_checked;
                });

                return proposta[0];
            },
            getScadenzaContrattuale(prodotto) {
                var proposta = this.getPropostaSelezionata(prodotto);

                return proposta.scadenza;
            },
            getPremioRata(prodotto) {
                var premio = 0;
                var frazionamento = this.getFrazionamentoPratica(prodotto);
                switch(frazionamento) {
                    case "ANNUALE":
                    case "PREMIO_UNICO":
                        premio = this.getPropostaSelezionata(prodotto).premio;
                    break;
                    case "SEMESTRALE":
                    premio = this.getPropostaSelezionata(prodotto).premio_semestrale;
                    break;
                }

                return premio.toFixed(2) + " Euro";
            },
            getProvvigioniRata(prodotto) {
                var provvigioni = this.getPropostaSelezionata(prodotto).provvigioni;
                return provvigioni.toFixed(2) + " Euro";
            },
            CalcolaProvvigioni(proposta) {
                return proposta.provvigioni.toFixed(2);
            },
            PuoLavorareLaPratica() {
                return policy.AbilitaLavorazionePreventivi();
            },
            PuoCancellareLaPratica() {
                return policy.PuoCancellarePraticheProprie() ||
                       policy.PuoCancellarePraticheGruppo() ||
                       policy.PuoCancellareTutteLePratiche();
            },
            onAnnullaPratica() {
                this.$q.dialog({
                    cancel: {
                        focus: true,
                        flat: false,
                        label: "No, neanche un po`",
                        color: "primary"
                    },
                    ok: {
                        color: "secondary",
                        flat: false,
                        label: "Si, annulla"
                    },
                    title: 'Attenzione',
                    message: 'Sei sicuro di voler annullare questa pratica?',
                }).onOk(async () => {
                    this.$router.push({name : "Pratiche.AnnullaPratica"});
                });
            },
            onSegnalaProblemi() {
                this.$emit("on-segnala-problemi");
            },
            onIndietroClicked() {
                this.$emit("on-back-clicked");
            },
            onAvantiClicked() {
                // Se arriva qui allora vuol dire che la pratica puo passare in lavorazione
                var id_pratica = this.formPratica.preventivo.id;
                this.setPraticaInLavorazione(id_pratica);

                this.$emit("on-avanti-clicked");
            },
            analizzaRisposta(parametro) {
                let result = "";

                if (parametro.type === "checkbox") {
                    return parametro.is_checked ? "Si" : "No";
                }

                var value = parametro.value;
                var options = parametro.options;

                // Cerca la label tra le options in base alla risposta inserita
                let element = options.filter(elemento => {
                    return elemento.value === value;
                })
                if (element.length === 1) {
                    value = element[0].label;
                }

                if ((value !== null) && (Array.isArray(value))) {
                    value.forEach(valore => {
                        result += '"'+valore+'", ';
                    });
                    result = result.slice(0, -2);
                    return result.replace("_"," ");
                } else {
                    if (value !== null) {
                        return value.replace("_"," ");
                    } else
                        return "";
                }
            }
        },
        async mounted() {
        //    var id_preventivo = this.formPratica.preventivo.id;
        //    this.elenco_documenti_scaricati = await this.fetchElencoDocumentiDaPreventivo(id_preventivo);
        //    this.elenco_documenti_scaricati = this.elenco_documenti_scaricati.data.result;
        },
        async activated() {
        //    var id_preventivo = this.formPratica.preventivo.id;
        //    this.elenco_documenti_scaricati = await this.fetchElencoDocumentiDaPreventivo(id_preventivo);
        //    this.elenco_documenti_scaricati = this.elenco_documenti_scaricati.data.result;
        }
    }
</script>
