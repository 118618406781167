<template>
    <div>
        <div align="center">
            <span class="text-h5">Verifica dei documenti caricati</span>
            <hr>
        </div>

        <div class="row justify-center">
            <div class="col-12 col-md-4 q-mr-sm">
                <!-- Visualizza la lista dei documenti richiesti al Cliente //-->
                <h5>Documenti richiesti per le proposte</h5>
                <q-list  bordered>
                    <q-item v-for="(documento,index) in elenco_documenti_richiesti" v-bind:key="index">
                        <q-item-section avatar>
                            <q-avatar color="orange-3" text-color="black" icon="mdi-text-box-check-outline" />
                        </q-item-section>
                        <q-item-section>
                            {{documento.descrizione_documento}}
                        </q-item-section>
                    </q-item>
                </q-list>
                <br>
            </div>
            <div class="col-12 col-md-6">

                <QQElencoDocumentiPratica
                    title=""
                    tabIndex="1"

                    :id_pratica="IDPratica"
                    :formPratica="formPratica"
                />
            </div>

            <div class="col-12 col-md-12">
                <hr>
            </div>

            <div class="row col-12 col-md-12 justify-center">

                <div class="col-md-4" align="center">
                    <QQButton label="INDIETRO" color="blue-grey" icon="undo" size="md"
                        @click.native="onIndietroClicked()"
                    />
                </div>
                <div class="col-md-4" align="center">
                    <QQButton label="Segnala problemi" color="blue-grey" icon="mdi-close-octagon" size="md"
                        @click.native="onSegnalaProblemi()"
                    />
                </div>
                <div class="col-md-4" align="center">
                    <QQButton label="AVANTI"
                        color="blue-grey"
                        icon="mdi-arrow-right-bold"
                        size="md"
                        icon_align="right"
                        @click.native="onAvantiClicked()"
                    />
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import { mapState,mapActions } from 'vuex';
    import commonLib from "@/libs/commonLib";
    import QQButton from "@/components/QQButton.vue";
    import QQElencoDocumentiPratica from "@/components/QQElencoDocumentiPratica.vue";

    export default {
        name: "ControlloDocumentiCaricati",
        components: {
            QQButton,
            QQElencoDocumentiPratica
        },
        data() {
            return {
                show: true,
                elenco_documenti_richiesti: []
            }
        },
        computed: {
            ...mapState({
                area: state => state.area,
                titolo_area: state => state.titolo_area,
                formPratica: state => state.gestionePratiche.formPratica
            }),
            IDPratica() {
                if (commonLib.isEmpty(this.formPratica)) return '';
                return this.formPratica.preventivo.id;
            }
        },
        methods: {
            ...mapActions({
                fetchElencoDocumentiPratica: "gestioneDocumenti/fetchElencoDocumentiPratica",
                // fetchElencoDocumentiDaPreventivo: "gestioneDocumenti/fetchElencoDocumentiDaPreventivo",
                fetchDownloadUrl: "gestioneDocumenti/fetchDownloadUrl"
            }),
            onIndietroClicked() {
                this.$emit("on-back-clicked");
            },
            onAvantiClicked() {
                this.$emit("on-avanti-clicked");
            },
            onSegnalaProblemi() {
                this.$emit("on-segnala-problemi");
            },
        },
        async mounted() {
            window.scrollTo(0,0);

            // Recupera tutti i prodotto_id
            var lista_prodotti_id = [];
            console.log("this.formPratica.preventivo.prodotti:",this.formPratica.preventivo.prodotti);
            this.formPratica.preventivo.prodotti.filter(prodotto => {
                if (prodotto.is_checked) {
                    lista_prodotti_id.push(prodotto.id);
                }
                return prodotto.is_checked;
            });

            this.elenco_documenti_richiesti = await this.fetchElencoDocumentiPratica(lista_prodotti_id);
        },
        async activated() {
            //this.elenco_documenti_richiesti = await this.fetchElencoDocumentiPratica();
        }
    }
</script>
