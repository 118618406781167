<template>
    <div>
        <br>
        <div align="center">
            <span class="text-h4">Controllo richiesta di emissione</span>
        </div>

        <q-stepper v-model="step"
            style="margin-top: 10px;"
            ref="stepper"
            animated
            flat
            alternative-labels
            no-header-navigation
            no-shadow
            transition-next="fade"
            transition-prev="slide-down"
            active-color="deep-orange"
            done-color="blue-grey-4">

            <q-step :name="1"
                title="Controllo documenti caricati"
                icon="list"
                done-icon="done"
                active-icon="list"
                :done="step > 1">
                <ControlloDocumentiCaricati
                    @on-avanti-clicked="onAvantiClicked"
                    @on-back-clicked="onBackClicked"
                    @on-segnala-problemi="onSegnalaProblemi"
                />
            </q-step>

            <q-step :name="2"
                title="Controllo dati del Cliente"
                icon="grading"
                done-icon="done"
                active-icon="grading"
                :done="step > 2">
                <ControlloDatiCliente
                    @on-avanti-clicked="onAvantiClicked"
                    @on-back-clicked="onBackClicked"
                    @on-segnala-problemi="onSegnalaProblemi"
                />
            </q-step>

            <q-step :name="3"
                title="Controllo Questionari"
                icon="done_all"
                done-icon="done"
                active-icon="done_all"
                :done="step > 3" >
                <ControlloQuestionari
                    @on-avanti-clicked="onAvantiClicked"
                    @on-back-clicked="onBackClicked"
                    @on-segnala-problemi="onSegnalaProblemi"
                />
            </q-step>

            <q-step :name="4"
                title="Controllo dati dei Contratti"
                icon="list_alt"
                done-icon="done"
                active-icon="list_alt"
                :done="step > 4" >
                <ControlloPolizze
                    @on-avanti-clicked="onAvantiClicked"
                    @on-back-clicked="onBackClicked"
                    @on-segnala-problemi="onSegnalaProblemi"
                />
            </q-step>

        </q-stepper>
        <br><br><br><br>
    </div>
</template>

<script>
import ControlloDocumentiCaricati from "@/pages/PiattaformaRami/Pratiche/ControlloRichiestaEmissione/ControlloDocumentiCaricati"
import ControlloDatiCliente from "@/pages/PiattaformaRami/Pratiche/ControlloRichiestaEmissione/ControlloDatiCliente"
import ControlloQuestionari from "@/pages/PiattaformaRami/Pratiche/ControlloRichiestaEmissione/ControlloQuestionari"
import ControlloPolizze from "@/pages/PiattaformaRami/Pratiche/ControlloRichiestaEmissione/ControlloPolizze"

//import store from "@/store"
// import commonLib from "@/libs/commonLib"
import { mapFields} from 'vuex-map-fields'
import { mapState } from 'vuex'

export default {
    name: "ControlloRichiestaEmissione",

    components: {
        ControlloDocumentiCaricati,
        ControlloDatiCliente,
        ControlloQuestionari,
        ControlloPolizze
    },

    data() {
        return {
            // step: 1
        }
    },
    methods: {
        //...mapActions({
        //    fetchElencoDocumentiPratica: "gestioneDocumenti/fetchElencoDocumentiPratica"
        //}),
        onAnalisiClicked() {
            //store.commit("formPreventivo/clear");
            //this.vuole_analisi_necessita = true;
            //this.step = 2;
        },
        onProdottoClicked() {
            //store.commit("formPreventivo/clear");
            //this.vuole_analisi_necessita = true;
            //this.step=2;
        },
        onSegnalaProblemi() {
            this.$router.push({name : "Pratiche.SegnalaProblemi"});
        },
        onBackClicked() {

            if (this.step > 1) {
                this.step -= 1;
                if (this.step < 1) this.step = 1;
            } else {
                this.step = 1;
                this.$router.push({name : "Pratiche.MenuGestionePratiche"});
            }
        },
        onAvantiClicked() {
            if (this.step < 4)
                this.step += 1;
            else {
                this.step = 1;
                this.$router.push({name : "Pratiche.MenuGestionePratiche"});
            }
        }
    },
    computed: {
        ...mapFields('formPreventivo', [
            "step"
        ]),
        ...mapFields('', [
            "elenco_documenti"
        ]),
        ...mapState({
            area: state => state.area,
            titolo_area: state => state.titolo_area,
            formPratica: state => state.gestionePratiche.formPratica
            //elenco_documenti: state => state.elenco_documenti
        }),
    /*    tasto_avanti_is_visible() {
            return (
                this.step > 2 || this.step <= 4
            )
        },
        tasto_avanti_label() {

            if(this.step == 4) {
                return "Calcola";
            }

            return "Avanti";
        } */
    }/*,
    async mounted() {
        window.scrollTo(0,0);
        this.elenco_documenti = await this.fetchElencoDocumentiPratica();

        console.log("elenco documenti:",this.elenco_documenti);
    }*/
}

</script>

<style>

</style>
