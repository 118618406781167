<template>
    <div>
        <div align="center">
            <span class="text-h5">Verifica dei dati del Cliente</span>
            <hr>
        </div>

        <div class="row justify-center">

            <div class="col-12 col-md-5 q-mr-sm">
                <!-- Visualizza la lista dei documenti richiesti al Cliente //-->
                <h5>Dati del Cliente selezionato</h5>

                <q-list bordered separator>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Nominativo</q-item-label>
                            <q-item-label caption>{{getNominativoCliente}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Tipo persona / Professione</q-item-label>
                            <q-item-label caption>{{getTipoPersona}} / {{getProfessione}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Codice Fiscale</q-item-label>
                            <q-item-label caption>{{getCodiceFiscale}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Partita IVA</q-item-label>
                            <q-item-label caption>{{getPartitaIva}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Indirizzo di residenza</q-item-label>
                            <q-item-label caption>{{getIndirizzoResidenza}}</q-item-label>
                        </q-item-section>

                        <q-item-section>
                            <q-item-label>Indirizzo recapito</q-item-label>
                            <q-item-label caption>{{getIndirizzoRecapito}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Comune di residenza</q-item-label>
                            <q-item-label caption>{{getComuneResidenza}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Comune recapito</q-item-label>
                            <q-item-label caption>{{getComuneRecapito}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Cap di residenza</q-item-label>
                            <q-item-label caption>{{getCapResidenza}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Cap recapito</q-item-label>
                            <q-item-label caption>{{getCapRecapito}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Provincia di residenza</q-item-label>
                            <q-item-label caption>{{getProvinciaResidenza}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Provincia recapito</q-item-label>
                            <q-item-label caption>{{getProvinciaRecapito}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Indirizzo EMAIL</q-item-label>
                            <q-item-label caption>{{getEmail}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Indirizzo PEC</q-item-label>
                            <q-item-label caption>{{getPec}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Numero di telefono</q-item-label>
                            <q-item-label caption>{{getTelefono}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Numero di cellulare</q-item-label>
                            <q-item-label caption>{{getCellulare}}</q-item-label>
                        </q-item-section>
                    </q-item>
                </q-list>
                <br>
            </div>

            <div class="col-12 col-md-5 q-mr-sm">
                <QQElencoDocumentiPratica
                    title=""
                    tabIndex = 1
                    :id_pratica="IDPratica"
                    :formPratica="formPratica"
                />
            </div>

            <div class="col-12 col-md-12">
                <hr>
            </div>

            <div class="row col-12 col-md-12 justify-center">

                <div class="col-md-3" align="center">
                    <QQButton label="INDIETRO" color="blue-grey" icon="undo" size="md"
                        @click.native="onIndietroClicked()"
                    />
                </div>
                <div class="col-md-3" align="center">
                    <QQButton label="Segnala problemi" color="blue-grey" icon="mdi-close-octagon" size="md"
                        @click.native="onSegnalaProblemi()"
                    />
                </div>
                <div class="col-md-3" align="center">
                    <QQButton label="Modifica dati Cliente" color="blue-grey" icon="mdi-file-document-edit-outline" size="md"
                        @click.native="gotoStart()"
                    />
                </div>
                <div class="col-md-3" align="center">
                    <QQButton label="AVANTI"
                        color="blue-grey"
                        icon="mdi-arrow-right-bold"
                        size="md"
                        icon_align="right"
                        @click.native="onAvantiClicked()"
                    />
                </div>
            </div>

        </div>

    </div>
</template>

<script>
    import { mapFields} from 'vuex-map-fields';
    import { mapState,mapActions } from 'vuex'
    import QQButton from "@/components/QQButton.vue";
    import QQElencoDocumentiPratica from "@/components/QQElencoDocumentiPratica.vue";
    import commonLib from "@/libs/commonLib";

    export default {
        name: "ControlloDatiCliente",
        components: {
            QQButton,
            QQElencoDocumentiPratica
        },
        data() {
            return {

            }
        },
        computed: {
            ...mapState({
                area: state => state.area,
                titolo_area: state => state.titolo_area,
                formPratica: state => state.gestionePratiche.formPratica
            }),
            ...mapFields("gestionePratiche", {
                dati_cliente: "dati_cliente",
            }),
            IDPratica() {
                return this.formPratica.preventivo.id;
            },
            getNominativoCliente() {
                if (commonLib.isEmpty(this.dati_cliente)) return "";

                var Cognome = this.dati_cliente.cognome.toUpperCase();
                var Nome    = this.dati_cliente.nome.toUpperCase();

                return Cognome+" "+Nome;
            },
            getTipoPersona() {
                if (commonLib.isEmpty(this.dati_cliente)) return "";
                return this.dati_cliente.tipo_persona.toUpperCase();
            },
            getProfessione() {
                if (commonLib.isEmpty(this.dati_cliente)) return "";
                return this.dati_cliente.professione.toUpperCase();
            },
            getCodiceFiscale() {
                if (commonLib.isEmpty(this.dati_cliente)) return "";
                return this.dati_cliente.codice_fiscale.toUpperCase();
            },
            getPartitaIva() {
                if (commonLib.isEmpty(this.dati_cliente)) return "";
                return this.dati_cliente.partita_iva.toUpperCase();
            },
            getIndirizzoResidenza() {
                if (commonLib.isEmpty(this.dati_cliente)) return "";
                return this.dati_cliente.indirizzo_residenza.toUpperCase();
            },
            getIndirizzoRecapito() {
                if (commonLib.isEmpty(this.dati_cliente)) return "";
                return this.dati_cliente.indirizzo_recapito.toUpperCase();
            },
            getComuneResidenza() {
                if (commonLib.isEmpty(this.dati_cliente)) return "";
                return this.dati_cliente.comune_residenza.toUpperCase();
            },
            getComuneRecapito() {
                if (commonLib.isEmpty(this.dati_cliente)) return "";
                return this.dati_cliente.comune_recapito.toUpperCase();
            },
            getCapResidenza() {
                if (commonLib.isEmpty(this.dati_cliente)) return "";
                return this.dati_cliente.cap_residenza.toUpperCase();
            },
            getCapRecapito() {
                if (commonLib.isEmpty(this.dati_cliente)) return "";
                return this.dati_cliente.cap_recapito.toUpperCase();
            },
            getProvinciaResidenza() {
                if (commonLib.isEmpty(this.dati_cliente)) return "";
                return this.dati_cliente.provincia_residenza.toUpperCase();
            },
            getProvinciaRecapito() {
                if (commonLib.isEmpty(this.dati_cliente)) return "";
                return this.dati_cliente.provincia_recapito.toUpperCase();
            },
            getEmail() {
                if (commonLib.isEmpty(this.dati_cliente)) return "";
                return this.dati_cliente.email;
            },
            getPec() {
                if (commonLib.isEmpty(this.dati_cliente)) return "";
                return this.dati_cliente.pec;
            },
            getTelefono() {
                if (commonLib.isEmpty(this.dati_cliente)) return "";
                return this.dati_cliente.telefono;
            },
            getCellulare() {
                if (commonLib.isEmpty(this.dati_cliente)) return "";
                return this.dati_cliente.cellulare;
            }
        },
        methods: {
            ...mapActions({
                fetchDatiCliente: "gestionePratiche/fetchDatiClientePratica",
                fetchDownloadUrl: "gestioneDocumenti/fetchDownloadUrl",
            }),
            onIndietroClicked() {
                this.$emit("on-back-clicked");
            },
            onAvantiClicked() {
                this.$emit("on-avanti-clicked");
            },
            onSegnalaProblemi() {
                this.$emit("on-segnala-problemi");
            }
        },
        async mounted() {
            var id_preventivo = this.formPratica.preventivo.id;
            await this.fetchDatiCliente(id_preventivo);
        },
        async activated() {
            var id_preventivo = this.formPratica.preventivo.id;
            await this.fetchDatiCliente(id_preventivo);
        }
    }
</script>
